import CSIR from '../Images/CSIR.webp';
import EXT from '../Images/EXT.webp';

export const experience = [
  {
    id: 0,
    cert: CSIR,
    title: 'Research Intership (CSIR - CIMAP)',
    position: 'Research Intern',
    field: 'Machine Learning and AI',
    org: 'Council for Scientific and Industrial Research - Central Institute for Medicinal and Aromatic Plants',
    time: 'May 2023 - July 2023',
    location: 'Lucknow, Uttar Pradesh',
    link: 'https://drive.google.com/file/d/1IOYcZjHsG5PfimcbSyIzIln_64MqjsR4/view?usp=drive_link',
  },
  {
    id: 1,
    cert: EXT,
    title: 'SmartInternz',
    position: 'Intern',
    field: 'Data Analytics',
    org: 'SmartInternz in collaboration with IBM',
    time: 'May 2023 - July 2023',
    location: 'Remote',
    link: 'https://drive.google.com/file/d/1eUw_BnhKM-_63BHDGFqfv0O-wVcbFoHH/view?usp=sharing',
  },
  {
    id: 2,
    cert: EXT,
    title: 'Jamrio',
    position: 'Intern',
    field: 'Backend Development with Sequelize and Express',
    org: 'Jamrio Technologies Pvt. Ltd.',
    time: 'August 2023 - October 2023',
    location: 'Remote',
    link: '',
  },
  {
    id: 3,
    cert: EXT,
    title: 'AstraZeneca Intership',
    position: 'Intern',
    field: 'Software Development',
    org: 'AstraZeneca India Private Limited',
    time: 'February 2024 - June 2024',
    location: 'Chennai, Tamil Nadu, India',
    link: 'https://drive.google.com/file/d/1eUw_BnhKM-_63BHDGFqfv0O-wVcbFoHH/view?usp=sharing',
  },
  {
    id: 4,
    cert: EXT,
    title: 'AstraZeneca',
    position: 'Associate Engineer',
    field: 'Software Development',
    org: 'AstraZeneca India Private Limited',
    time: 'July 2024 - Present',
    location: 'Chennai, Tamil Nadu, India',
    link: 'https://drive.google.com/file/d/1eUw_BnhKM-_63BHDGFqfv0O-wVcbFoHH/view?usp=sharing',
  }
];